<template>
  <div class="games">
    <b-container class="py-3">
      <page-title title="Játékok">
        <template #action>
          <b-button
            :disabled="!uid"
            :to="{ name: 'CreateGame' }"
            variant="primary"
          >
            Új játék
          </b-button>
        </template>
      </page-title>

      <b-overlay :show="!gameModuleLoaded" no-wrap spinner-variant="primary" />
      <div v-if="gameModuleLoaded">
        <search-games class="mb-3" />

        <page-title title="Összes játék" titleTag="h5">
          <template #action>
            <b-dropdown :text="$t(`sort-by-${sortBy}`)" variant="link">
              <b-dropdown-item
                v-for="option in ['name', 'createdAt', 'rating', 'ratingCount']"
                :key="option"
                :active="sortBy === option"
                @click="sortBy = option"
                >{{ $t(`sort-by-${option}`) }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </page-title>
        <b-row>
          <template v-for="game in sortedGames">
            <b-col cols="12" sm="6" lg="4" :key="game.id" class="mb-3">
              <game-card :game="game" />
            </b-col>
            <b-col
              cols="12"
              sm="6"
              lg="4"
              :key="`ad-${game.id}`"
              class="mb-3"
              v-if="!headless && showAdRnd()"
            >
              <ad />
            </b-col>
          </template>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";
import displayUrl from "@/utils/displayUrl";
import GameModuleMixin from "../mixins/GameModuleMixin";
import AuthMixin from "../mixins/AuthMixin";
import GameCard from "../components/GameCard.vue";
import saveState from "vue-save-state";
import Ad from "../components/Ad.vue";
import SearchGames from "../components/SearchGames.vue";

export default {
  name: "Games",
  components: { PageTitle, GameCard, Ad, SearchGames },
  mixins: [GameModuleMixin, saveState, AuthMixin],
  data: () => ({
    sortBy: "rating"
  }),
  computed: {
    sortedGames() {
      const filtered = [...this.gameModuleItems.filter((g) => !!g.content)];
      switch (this.sortBy) {
        case "name":
          return filtered.sort((a, b) => {
            const an = a.content.name.toLowerCase();
            const bn = b.content.name.toLowerCase();
            if (an < bn) return -1;
            if (an > bn) return 1;
            return 0;
          });
        case "createdAt":
          return filtered.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        case "rating":
          return filtered.sort((a, b) => {
            if (!a.rating.average) return 1;
            if (!b.rating.average) return -1;
            return b.rating.average - a.rating.average;
          });
        case "ratingCount":
          return filtered.sort((a, b) => b.rating.count - a.rating.count);
        default:
          return filtered;
      }
    }
  },
  methods: {
    getSaveStateConfig() {
      return {
        cacheKey: "games",
        saveProperties: ["sortBy"] //"searchTerm"
      };
    },
    showAdRnd() {
      const r = Math.random();
      return r < 1 / 8;
    },
    displayUrl
  }
};
</script>
