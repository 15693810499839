<template>
  <div class="mb-3">
    <b-row>
      <b-col>
        <b-form-group
          description="Keresés a játékok nevében, lead-jében és leírásában"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon-search />
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Keress!"
              v-model="filter.searchTerm"
              debounce="250"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-button v-b-toggle.advanced-filter variant="link">
          <b-icon-chevron-up class="when-open" />
          <b-icon-chevron-down class="when-closed" />
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="advanced-filter" class="mt-2">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group>
            <b-input-group>
              <b-input-group-addon is-text>
                <b-icon-person-badge />
              </b-input-group-addon>
              <b-form-select
                v-model="filter.ageLimit.relation"
                :options="relations"
              />
              <b-form-input
                type="number"
                v-model="filter.ageLimit.value"
                placeholder="Korhatár"
                debounce="250"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group>
            <b-input-group>
              <b-input-group-addon is-text>
                <b-icon-people />
              </b-input-group-addon>
              <b-form-input
                v-model="filter.playerCount.value"
                type="number"
                placeholder="Játékosok"
                debounce="250"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group>
            <b-input-group>
              <b-input-group-addon is-text>
                <b-icon-clock />
              </b-input-group-addon>
              <b-form-select
                v-model="filter.playTime.relation"
                :options="relations"
              />
              <b-form-input
                type="number"
                v-model="filter.playTime.value"
                placeholder="Játékidő"
                debounce="250"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-input-group>
              <b-input-group-addon is-text>
                <b-icon-hash />
              </b-input-group-addon>
              <datalist id="tags-list">
                <option
                  v-for="tag in availableTags"
                  :key="tag.text"
                  :value="tag.text"
                  >{{ tag.count }} játék</option
                >
              </datalist>
              <b-form-tags
                v-model="filter.tags.value"
                :input-attrs="{ list: 'tags-list' }"
                add-button-text="+"
                add-button-variant="primary"
                add-on-change
                :limit="5"
                limit-tags-text="Maximum 5 címkére tudsz keresni"
                placeholder="Keress címkékre"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-collapse>

    <div v-if="filterSet">
      <page-title title="Keresési találatok" titleTag="h5">
        <template #action>
          <span class="text-primary" v-if="filteredGames.length">
            <strong>
              {{ filteredGames.length }}
            </strong>
            találat
          </span>
          <b-button variant="link" size="sm" @click="clearFilters">
            <b-icon-x-circle />
          </b-button>
        </template>
      </page-title>
      <p v-if="filteredGames.length" class="text-muted">
        <small> Keresés: <span v-html="filterDisplay" /> </small>
      </p>
      <b-alert variant="danger" :show="!filteredGames.length">
        Nincs találat erre a keresésre:
        <span v-html="filterDisplay" />
      </b-alert>
      <b-list-group>
        <b-list-group-item
          v-for="game in filteredGames"
          :key="game.id"
          :to="{
            name: 'ViewGame',
            params: { gamePath: game.content.path }
          }"
        >
          <strong>
            {{ game.content.name }}
          </strong>
          <br />
          <small>
            {{ displayUrl(game.content.url) }}
          </small>
          <br />
          <small>
            {{ game.content.lead }}
          </small>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>
<script>
import PageTitle from "../components/PageTitle.vue";
import displayUrl from "@/utils/displayUrl";
import GameModuleMixin from "../mixins/GameModuleMixin";
import { mapGetters } from "vuex";
export default {
  name: "SearchGames",
  components: { PageTitle },
  mixins: [GameModuleMixin],
  data: () => ({
    filter: {
      searchTerm: null,
      ageLimit: { value: null, relation: "<=" },
      playerCount: { value: null },
      playTime: { value: null, relation: "<=" },
      tags: { value: null, match: "all" }
    },
    relations: ["<=", ">="],
    matches: ["all", "any"]
  }),
  mounted() {
    this.parseQuery();
  },
  computed: {
    ...mapGetters("gameModule", ["gameTags"]),
    availableTags() {
      return this.gameTags.filter(
        (t) =>
          !this.filter.tags.value || !this.filter.tags.value.includes(t.text)
      );
    },
    filterDisplay() {
      const filters = [];
      if (this.filter.searchTerm)
        filters.push(`<strong>"${this.filter.searchTerm}"</strong>`);
      if (!isNaN(parseInt(this.filter.ageLimit.value)))
        filters.push(`korhatár <strong>${this.filter.ageLimit.relation} \
          ${this.filter.ageLimit.value}</strong> év`);
      if (!isNaN(parseInt(this.filter.playerCount.value)))
        filters.push(`<strong>${this.filter.playerCount.value}</strong>\
          játékos`);
      if (!isNaN(parseInt(this.filter.playTime.value)))
        filters.push(`játékidő <strong>${this.filter.playTime.relation} \
          ${this.filter.playTime.value}</strong> perc`);
      if (this.filter.tags.value && this.filter.tags.value.length) {
        const tagsString = this.filter.tags.value
          .map((t) => `<strong>#${t}</strong>`)
          .join(", ");
        filters.push(`címkék: ${tagsString}`);
      }
      return filters.join(", ");
    },
    filterSet() {
      return (
        !!this.filter.searchTerm ||
        !!this.filter.ageLimit.value ||
        this.filter.ageLimit.value === 0 ||
        !!this.filter.playerCount.value ||
        this.filter.playerCount.value === 0 ||
        !!this.filter.playTime.value ||
        this.filter.playTime.value === 0 ||
        (this.filter.tags.value && this.filter.tags.value.length)
      );
    },
    filteredGames() {
      if (!this.filterSet) return [];

      const searchTermMatch = (c) => {
        if (!this.filter.searchTerm) return true;
        try {
          const re = new RegExp(this.filter.searchTerm, "i");
          const match = {
            name: !!c.name.match(re),
            url: !!c.url.match(re),
            lead: !!c.lead.match(re),
            description: !!JSON.stringify(c.description || {}).match(re)
          };
          return Object.values(match).includes(true);
        } catch (error) {
          console.log(error);
          return false;
        }
      };

      const ageLimitMatch = (c) => {
        if (!this.filter.ageLimit.value) return true;
        const al = parseInt(c.ageLimit);
        if (isNaN(al)) return false;
        switch (this.filter.ageLimit.relation) {
          case "<=":
            return al <= this.filter.ageLimit.value;
          case ">=":
            return al >= this.filter.ageLimit.value;
        }
      };

      const playerCountMatch = (c) => {
        if (!this.filter.playerCount.value) return true;
        if (c.playerCount === undefined || c.playerCount === null) return false;
        const from = parseInt(c.playerCount.from);
        const to = parseInt(c.playerCount.to);
        const lowerOk = isNaN(from) || from <= this.filter.playerCount.value;
        const upperOk = isNaN(to) || to >= this.filter.playerCount.value;
        return lowerOk && upperOk;
      };

      const playTimeMatch = (c) => {
        if (!this.filter.playTime.value) return true;
        const pt = parseInt(c.playTime);
        if (isNaN(pt)) return false;
        switch (this.filter.playTime.relation) {
          case "<=":
            return pt <= this.filter.playTime.value;
          case ">=":
            return pt >= this.filter.playTime.value;
        }
      };

      const tagsMatch = (c) => {
        if (!this.filter.tags.value || !this.filter.tags.value.length)
          return true;
        if (!c.tags || !c.tags.length) return false;
        switch (this.filter.tags.match) {
          case "all":
            return !this.filter.tags.value
              .map((f) => c.tags.includes(f))
              .includes(false);
          case "any":
            return this.filter.tags.value
              .map((f) => c.tags.includes(f))
              .includes(true);
        }
      };

      return this.gameModuleItems.filter((game) => {
        if (!game.content) return false;
        return (
          searchTermMatch(game.content) &&
          ageLimitMatch(game.content) &&
          playerCountMatch(game.content) &&
          playTimeMatch(game.content) &&
          tagsMatch(game.content)
        );
      });
    }
  },
  methods: {
    parseQuery() {
      this.clearFilters();
      const {
        kereses,
        korhatar,
        jatekosok,
        jatekido,
        cimkek
      } = this.$route.query;
      if (kereses) this.filter.searchTerm = kereses;
      if (korhatar) {
        const relation = korhatar.substring(0, 2);
        const value = parseInt(korhatar.substring(2));
        if (this.relations.includes(relation) && !isNaN(value))
          this.filter.ageLimit = { value, relation };
      }
      if (jatekido) {
        const relation = jatekido.substring(0, 2);
        const value = parseInt(jatekido.substring(2));
        if (this.relations.includes(relation) && !isNaN(value))
          this.filter.playTime = { value, relation };
      }
      if (!isNaN(parseInt(jatekosok)))
        this.filter.playerCount.value = parseInt(jatekosok);
      if (cimkek) {
        try {
          const value = JSON.parse(cimkek);
          this.filter.tags = { value, match: this.filter.tags.match };
        } catch (error) {
          console.log(error);
        }
      }
    },
    clearFilters() {
      this.filter.searchTerm = this.filter.playerCount.value = this.filter.ageLimit.value = this.filter.playTime.value = this.filter.tags.value = null;
    },
    displayUrl
  },
  watch: {
    "$route.query": {
      deep: true,
      async handler() {
        this.parseQuery();
      }
    },
    filter: {
      deep: true,
      async handler(f) {
        const query = {};
        if (f.searchTerm) query.kereses = f.searchTerm;
        if (f.ageLimit.value || f.ageLimit.value === 0)
          query.korhatar = f.ageLimit.relation + f.ageLimit.value;
        if (f.playerCount.value || f.playerCount.value === 0)
          query.jatekosok = f.playerCount.value;
        if (f.playTime.value || f.playTime.value === 0)
          query.jatekido = f.playTime.relation + f.playTime.value;
        if (f.tags.value && f.tags.value.length)
          query.cimkek = JSON.stringify(f.tags.value);

        try {
          await this.$router.replace({ query });
        } catch (error) {
          console.groupCollapsed("navigation error");
          console.log(error);
          console.groupEnd();
        }
      }
    }
  }
};
</script>
